export var prefix = '@@redux-form/';
export var ARRAY_INSERT = prefix + "ARRAY_INSERT";
export var ARRAY_MOVE = prefix + "ARRAY_MOVE";
export var ARRAY_POP = prefix + "ARRAY_POP";
export var ARRAY_PUSH = prefix + "ARRAY_PUSH";
export var ARRAY_REMOVE = prefix + "ARRAY_REMOVE";
export var ARRAY_REMOVE_ALL = prefix + "ARRAY_REMOVE_ALL";
export var ARRAY_SHIFT = prefix + "ARRAY_SHIFT";
export var ARRAY_SPLICE = prefix + "ARRAY_SPLICE";
export var ARRAY_UNSHIFT = prefix + "ARRAY_UNSHIFT";
export var ARRAY_SWAP = prefix + "ARRAY_SWAP";
export var AUTOFILL = prefix + "AUTOFILL";
export var BLUR = prefix + "BLUR";
export var CHANGE = prefix + "CHANGE";
export var CLEAR_FIELDS = prefix + "CLEAR_FIELDS";
export var CLEAR_SUBMIT = prefix + "CLEAR_SUBMIT";
export var CLEAR_SUBMIT_ERRORS = prefix + "CLEAR_SUBMIT_ERRORS";
export var CLEAR_ASYNC_ERROR = prefix + "CLEAR_ASYNC_ERROR";
export var DESTROY = prefix + "DESTROY";
export var FOCUS = prefix + "FOCUS";
export var INITIALIZE = prefix + "INITIALIZE";
export var REGISTER_FIELD = prefix + "REGISTER_FIELD";
export var RESET = prefix + "RESET";
export var RESET_SECTION = prefix + "RESET_SECTION";
export var SET_SUBMIT_FAILED = prefix + "SET_SUBMIT_FAILED";
export var SET_SUBMIT_SUCCEEDED = prefix + "SET_SUBMIT_SUCCEEDED";
export var START_ASYNC_VALIDATION = prefix + "START_ASYNC_VALIDATION";
export var START_SUBMIT = prefix + "START_SUBMIT";
export var STOP_ASYNC_VALIDATION = prefix + "STOP_ASYNC_VALIDATION";
export var STOP_SUBMIT = prefix + "STOP_SUBMIT";
export var SUBMIT = prefix + "SUBMIT";
export var TOUCH = prefix + "TOUCH";
export var UNREGISTER_FIELD = prefix + "UNREGISTER_FIELD";
export var UNTOUCH = prefix + "UNTOUCH";
export var UPDATE_SYNC_ERRORS = prefix + "UPDATE_SYNC_ERRORS";
export var UPDATE_SYNC_WARNINGS = prefix + "UPDATE_SYNC_WARNINGS";
export default {
  ARRAY_INSERT: ARRAY_INSERT,
  ARRAY_MOVE: ARRAY_MOVE,
  ARRAY_POP: ARRAY_POP,
  ARRAY_PUSH: ARRAY_PUSH,
  ARRAY_REMOVE: ARRAY_REMOVE,
  ARRAY_REMOVE_ALL: ARRAY_REMOVE_ALL,
  ARRAY_SHIFT: ARRAY_SHIFT,
  ARRAY_SPLICE: ARRAY_SPLICE,
  ARRAY_UNSHIFT: ARRAY_UNSHIFT,
  ARRAY_SWAP: ARRAY_SWAP,
  AUTOFILL: AUTOFILL,
  BLUR: BLUR,
  CHANGE: CHANGE,
  CLEAR_FIELDS: CLEAR_FIELDS,
  CLEAR_SUBMIT: CLEAR_SUBMIT,
  CLEAR_SUBMIT_ERRORS: CLEAR_SUBMIT_ERRORS,
  CLEAR_ASYNC_ERROR: CLEAR_ASYNC_ERROR,
  DESTROY: DESTROY,
  FOCUS: FOCUS,
  INITIALIZE: INITIALIZE,
  REGISTER_FIELD: REGISTER_FIELD,
  RESET: RESET,
  RESET_SECTION: RESET_SECTION,
  SET_SUBMIT_FAILED: SET_SUBMIT_FAILED,
  SET_SUBMIT_SUCCEEDED: SET_SUBMIT_SUCCEEDED,
  START_ASYNC_VALIDATION: START_ASYNC_VALIDATION,
  START_SUBMIT: START_SUBMIT,
  STOP_ASYNC_VALIDATION: STOP_ASYNC_VALIDATION,
  STOP_SUBMIT: STOP_SUBMIT,
  SUBMIT: SUBMIT,
  TOUCH: TOUCH,
  UNREGISTER_FIELD: UNREGISTER_FIELD,
  UNTOUCH: UNTOUCH,
  UPDATE_SYNC_ERRORS: UPDATE_SYNC_ERRORS,
  UPDATE_SYNC_WARNINGS: UPDATE_SYNC_WARNINGS
};